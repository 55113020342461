import React from "react"
import BackgroundImage from 'gatsby-background-image'
import { StaticQuery, graphql } from "gatsby"
import PropTypes from "prop-types"

import CountryImage from './countryImage'
 
class GuestBook extends React.Component {

    generateStars(numStars){
        var result = [];
        var step = 1;
        for (var i = step; i <= numStars; i=i+step){
            result.push(<i className="fa fa-star" key={"star-" + i}></i>);
        }   
        return result;
    };


    renderContent(data){
        return <section>
                {this.props.group.map((c) => {
                    return <div 
                        style={{
                            marginTop: `20px`,
                            marginBottom: `20px`
                        }}
                        key={"review" + c.id}
                    >
                        <BackgroundImage Tag="section"
                            fluid={data.reviewBg.childImageSharp.fluid}
                            className="review-section"
                        >
                                <div className="review-item" >
                                    <div className="rating">
                                        {this.generateStars(c.Puntuacion)}
                                    </div>
                                    <p>“{c.Comentario}”</p>
                                    <h5>{c.Nombre}</h5>
                                    {c.Fecha &&
                                        <span>{(new Date(c.Fecha)).toLocaleDateString()}</span>
                                    }
                                    <CountryImage countryName={c.Pais}></CountryImage>
                                </div>
                        </BackgroundImage> 
                        </div>                    
                })}
            </section>
    };

    render(){
        return(
        <StaticQuery
            query={graphql`
            query {
                    reviewBg: file(relativePath: { eq: "guestbook/review-bg.jpg" }) {
                        childImageSharp {
                            fluid(maxWidth: 1000) {
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            `}
            render={data => 
                this.renderContent(data)
            }
        />
        )
    }
}

GuestBook.propTypes = {
    group: PropTypes.array.isRequired
}

export default GuestBook
