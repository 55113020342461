import React from 'react'
import Layout from "../components/layout"
import SEO from "../components/seo"
import GuestBook from "../components/GuestBook/GuestBook"
import { injectIntl, useIntl, Link } from "gatsby-plugin-intl";

const NavLink = props => {
  if (!props.test) {
    return <Link to={props.url}>{props.text}</Link>
  } else {
    return <span>{props.text}</span>
  }
}

const ClientComments = ({ pageContext }) => {
  const intl = useIntl();
  const { group, index, pageCount } = pageContext
  const previousUrl = index - 1 === 1 ? '/guestbook' : `/guestbook/${(index - 1).toString()}`
  const nextUrl = `/guestbook/${(index + 1).toString()}`

  const getPagesLink = () => {
    let pageLinks = [];
    for(let i = 1; i <= pageCount; i++) {
      if ([1, index - 1, index, index + 1, pageCount].indexOf(i) >= 0) {
        pageLinks.push(<NavLink 
          key={`guestbook-${i}-link`}
          test={index === i}
          url={i === 1 ? '/guestbook' : `/guestbook/${i.toString()}`} 
          text={i} 
        />)
      }
      if ([1, 2, 3].indexOf(index) < 0 && i === 1) {
        pageLinks.push(<span key={`guestbook-${i}-points`}>...</span>);
      }
      if ([pageCount - 2, pageCount - 1, pageCount].indexOf(index) < 0 && (i === pageCount - 1)) {
        pageLinks.push(<span key={`guestbook-${i}-points`}>...</span>);
      }
    }
    return pageLinks;
  }

  return (
    <Layout>
        <SEO title={intl.formatMessage({ id:'Menu-clientComments' })} />
        <section>
            <div className="mainTitle">
                <h2>{intl.formatMessage({ id:'Menu-clientComments' })}</h2>
            </div>

            {/* SLIDER REVIEWS SECTION */}
            <GuestBook group={group}></GuestBook>

            <section className="pagination-links">
              <NavLink test={index === 1} url={previousUrl} text="Previous -" />
              {getPagesLink()}
              <NavLink test={index === pageCount} url={nextUrl} text="- Next" />
            </section>

        </section>
    </Layout>

  )
}

export default injectIntl(ClientComments)